
// @import '~antd/dist/antd.css';

// Color Palette
$black: #191919;
$grey: #696969; 
$lightgrey: #9FA2B4; 
$main-color: #9a7f3e;
$sub-color: #D8BD5C;
$base: #c2a255;
$normal: #d3b160;
$light: #debb67;
$muted-color: mix(white, $grey, 70%);
$green: #3abc19;
$red: #f12b2c;
$orange: #ff7f14;

// colors
$base-font-color: #191919;
$white: #fff;
$black: #191919;
$blue-dark: #392fc0;
$blue: #152c59;
$blurple: #5e367c;
$blurple-dark: #3f33d2;
$card-bg: #bddef4;
$dark-blue: #528fb5;
$divider-dark: #dbdbdb;
$footer: #152c59;
$gray-dark: #555555;
$gray-light: #f0f0f0;
$gray: #241c1c; 
$light-bg: #f4f5f7;
$light-blue: #76aad3;
$light-font: #767676;
$rose: #fe6e76;
$turquoise-dark: #b4b0ff;
$turquoise-light: #f1f0ff;
$turquoise: #76aad3;
$white: #fff;
$yellow: #fcda2a;
$yellow-dark: #eccb27;
$blue-overlay: rgba($blue, 0.15);
$autofillColor: rgb(250, 255, 189);
$autofillColorSelected: rgb(232, 233, 254);
$darkGreen: #06847b;
$pink: #e04fd2;

$base-font-family: Roboto;
$strong-font-family: 'Roboto';
$base-font-size: 1rem; // 16px
$small-font-size: 0.875rem; // 14px
$base-line-height: 1.25rem; // 20px
$hero-font-size: 4rem;
$h1-font-family: Roboto;
$h1-font-size: 4rem; // 64px
$h1-line-height: 1;
$subheading-font-family: Roboto;
$h3-font-family: Roboto;
$h3-font-size: 1.5rem; // 24px
$h4-font-family: Roboto;
$p-font-family: Roboto;
$a-font-family: Roboto;
$h4-font-size: 1.125rem; // 18px
$bold-font-weight: 600;
$extra-bold-font-weight: 900;
$normal-font-family: Roboto;
$header-font-size: 1.5rem; // 24px; 
$subheader-font-size: 1.25rem; // 20px; 
$header-mobile-font-size: 1.25rem; // 20px;
$subheader-mobile-font-size: 1.125rem; //18px;
$base-mobile-font-size: 1rem; // 16px;

// spacing
$spacer: 1rem; // 16px

// grid
$container-width: 1140px;

// button
$button-font-size: 1.125rem;
$button-line-height: $base-line-height;
$button-box-shadow: -5px 5px 14px 0px rgba(0, 0, 0, 0.2);
$button-box-shadow-clicked: -3px 3px 14px 0px rgba(129, 67, 67, 0.2);
$button-padding: 0.9rem 3.7rem;
$button-padding-small-screen: 0.9rem 1rem;
$button-transition: 0.3s;
$button-transform: skew(45deg);
$button-content-transform: skew(-45deg);
$button-text-transform: capitalize;
$button-font-weight: $bold-font-weight;

// input
$label-font-size: 0.75rem; // 12px
$label-color: $gray-dark;
$select-menu-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);

// message
$message-width: 25rem;
$message-padding: 1.5rem;
$message-background-color: $white;
$message-shadow: 0px 6px 15px 3px rgba(0, 0, 0, 0.25);
$message-right-position: $spacer;
$message-botttom-position: $spacer;
$message-success-color: $green;
$message-error-color: $rose;
$message-neutral-color: $turquoise-dark;
$message-border-left: 0.3rem solid $message-neutral-color;
$message-title-transform: capitalize;
$message-title-weight: $bold-font-weight;
$message-title-margin: 0 $spacer * 1.5 0 0;
$message-content-margin: $spacer 0 0;

// product item
$product-item-title-text-transform: capitalize;
$product-item-title-font-weight: $bold-font-weight;
$product-item-title-margin: calc($spacer / 2) 0 0;
$product-item-title-height: 2.5rem;
$product-item-category-color: $gray;
$product-item-price-font-weight: $bold-font-weight;
$product-item-price-margin: $spacer 0 0;

// breakpoints
$xlarge-screen: 1265px;
$large-screen: 992px;
$medium-screen: 768px;
$small-screen: 540px;

// carousel
$carousel-control-padding: 0rem 0rem;
$carousel-control-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

// container
$container-width: 1140px;
$full-width: 100%;

// modal
$modal-width: 555px;
$modal-min-height: 455px;

:export {
  xlargeScreen: $xlarge-screen;
  largeScreen: $large-screen;
  mediumScreen: $medium-screen;
  smallScreen: $small-screen;
}


//transition
$trans-time: 300ms;
