@import "./globalScss/variables.scss";

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .swal2-select{
    border-color: $black; 
    border: solid 1px;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: $black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  } 
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  input[type=button]:focus {
    outline: none;
  } 
  
  input[type=button] {
    outline:none;
  }
  input[type=button]::-moz-focus-inner {
    border: 0;
  }
  
  button.black-button:disabled,
  button.black-button[disabled],
  button.black-button:hover:disabled,
  button.black-button:hover[disabled]{ 
    background-color: #fff;
    color: $black;
    letter-spacing: 0.025em;
    border: none;
  }  
  
  button:disabled,
  button[disabled],
  button:hover:disabled,
  button:hover[disabled]{  
    color: white;
    letter-spacing: 0.025em;
    border: none;
    opacity: 0.5;
  }  
  
  
  button:disabled.btn-disabled,
  button[disabled].btn-disabled,
  button:hover:disabled.btn-disabled,
  button:hover[disabled].btn-disabled{ 
    background-color: white;
    color: $black !important;
    letter-spacing: 0.025em;
    border: none;  
  } 
     
  
  *:focus {
    outline: 0 !important;
  } 
  
  .icon::-webkit-input-placeholder { 
    font-family: FontAwesome, "Segoe UI", "Roboto";
    font-weight: normal;
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: $grey
    }
    
    .icon::-moz-placeholder  { 
    font-family: FontAwesome, "Segoe UI", "Roboto";
    font-weight: normal;
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: $grey
    }
    
    .icon:-ms-input-placeholder  { 
    font-family: FontAwesome, "Segoe UI", "Roboto";
    font-weight: normal;
    overflow: visible;
    vertical-align: top;
    display: inline-block !important;
    padding-left: 5px;
    padding-top: 2px;
    color: $grey;
    }
  
    .row { 
      margin-left: 0 !important;
  }
  
  .background-grey {
    background-image: url('./assets/img/web/background-grey.png');
  }
  
  .background-grey:focus {
    background-image: url('./assets/img/web/background-green.png');
  }
  
  input#file, input#upload{
    display:none
  }
  
  input#file + label{ 
    padding: 0.5rem;
    /* border: 2px solid #191919; */
    width: 100%;
    font-weight: 400;
    font-size: 1rem;
    cursor: pointer;
    background: $main-color;
    color: #fff;
    border-radius: 0.2rem;

    @media only screen and (max-width: 1200px) {  
      font-size: 0.87rem;
    }
  } 

  input#upload + label{ 
    padding: 0.55rem;
    border: 1px solid rgb(222 226 230);;
    width: 100%;
    font-weight: 400;
    font-size: 0.85rem;
    cursor: pointer;  
  } 
  
  Spinner, .spinner {
    color: $main-color !important;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
  }
  
  .container-fluid { 
    padding-left: 0 !important;

    @media only screen and (max-width: 1200px) { 
      padding-right: 0 !important;
    } 
  } 
  
  // input[type = checkbox]:checked {
  //     background-color: $main-color !important;
  //     border: 1px solid $main-color !important; 
  // }
  
  .padding-35 {
    padding: 0.35rem;
  }

.active {
  border-bottom: 2px solid $main-color;
}

// TAB NAVIGATION
.headertab {
  z-index: 10;
  position: fixed;
  background: white;
  width: 100%;
  padding:  8px 16px;
  display: flex;    
  margin-left: 100px;
  left: 0px;
  right: 0px;
  top: 0px;
  border-bottom: solid 1px lightgray;
  
}

@media only screen and (max-width: 1200px) { 
  .headertab {
    margin-left: 0;
    width: 100vw;
  }

  .headertab img {
    height: 2rem;
    width: 5rem;
  }

  .headertab button {
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem 0 1rem;
  }
}

.boxNavigation {
  display: none;

  @media only screen and (max-width: 1200px) { 
    display: block; 
    // position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #fff;
    // padding: 5px 0;
  }
}

.css-1ee5err-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected{
  color: $main-color !important;
}

.css-imwso6-MuiBottomNavigationAction-label {
  color: $main-color !important;
  font-size: 1rem !important;
}

// table
.maintable { 
  display: block;

  @media only screen and (max-width: 1200px) {  
    display: none;
  } 
}

.responsive-content {
  display: none;

  .card {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0.375rem; /* 6px */
    background-color: #fff;
    padding: 0.5rem; /* 8px */
    margin-top: 1rem;
  }

  @media only screen and (max-width: 1200px) {
    display: block;
    // margin-top: 10px;
    // padding: 1rem;
  } 
}

// Autocomplete 
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: $main-color !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $main-color !important;
} 