$theme-colors: (
  "primary": #00b4e6,
  "light": #d0fbff,
);

$grid-breakpoints: (
  xs: 0,
  sm: 700px,
  md: 900px,
  lg: 1200px,
  xl: 1500px,
  xxl: 2300px,
);

$container-max-widths: (
  sm: 600px,
  md: 800px,
  lg: 1100px,
  xl: 1300px,
  xxl: 2000px,
);

@import "node\_modules/bootstrap/scss/bootstrap";
