@import "./reset.scss";
@import "./variables.scss";

body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $base-font-color;
  font-weight: 500;
  // @media (max-width: 540px) {
  //   background: $light-bg;
  // }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-variant-ligatures: no-common-ligatures;
  }

  h1 {
    font-family: $h1-font-family;
    font-size: $h1-font-size;
    line-height: $h1-line-height;
  }

  h3 {
    font-family: $h3-font-family;
    font-size: $h3-font-size;
    line-height: 1.7rem;
  }

  h4 {
    font-family: $h4-font-family;
    font-size: $h4-font-size;
  }
  h5 {
    font-family: $base-font-family;
  }

  a {
    font-family: $a-font-family;
    text-decoration: none;
    font-weight: unset; 
  }
  a:hover {
    text-decoration: none;
    color: $sub-color;
    h3,
    h4 {
      transition: 0.3s ease;
      color: $gray-dark;
    }
  }
  a:active {
    color: $sub-color;
  }
  p {
    font-family: $base-font-family;
    line-height: 1.5rem;
  }

  li {
    font-family: $base-font-family;
  }
  span {
    font-family: $base-font-family;
  }

  strong {
    font-family: $h4-font-family;
  }

  div {
    font-family: $base-font-family;
  }

  em {
    font-style: italic;
  }

  button {
    border: none;
    span {
      font-family: $base-font-family !important;
      font-weight: unset !important;
    }
  }

  #root {
    display: flex;
    min-height: 100vh;
    height: 100vh;
    flex-direction: column;

    & > div:first-of-type {
      flex: 1;
    }
  }

  .container {
    width: $container-width;
    max-width: 100vw;
    margin: 0 auto;
    // padding: 0 $spacer;

    @media (max-width: $medium-screen) {
      width: 100%;
    }
  }

  table {
    width: 100%;
    margin: $spacer * 3 0;
    font-size: $small-font-size;

    th {
      // color: $gray-dark;
      text-align: center;
      padding: $spacer calc($spacer / 2);
    }

    tr {
      color: $base-font-color;
      // border-bottom: 1px solid $gray-light;
    }

    td {
      padding: $spacer calc($spacer / 2);
      vertical-align: middle;

      img {
        vertical-align: middle;
        margin-right: $spacer;
      }
    }
  }

  input[type = checkbox]{
    cursor: pointer;
    -webkit-appearance: none; 
    border: 1px solid $grey;  
    border-radius: 3px;
    display: inline-block; 
  }

  input[type = checkbox]:checked {
      background-color: $main-color;
      border: 1px solid $grey; 
  }
 

  .checkbox {
    position: relative;
    display: inline;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0.4rem;
      top: 0.05rem;
      width: 0.3rem;
      height: 0.6rem;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      z-index: 1;
    }

    input {
      display: none;

      &:checked ~ span {
        &:before {
          background-color: $main-color;
        }
      }
    }

    span {
      margin-left: $spacer * 2;

      &:before {
        background-color: $white;
        border-radius: 0.2rem;
        border: 1px solid $blue;
        content: "";
        display: block;
        height: 1rem;
        position: absolute;
        top: 0;
        width: 1rem;
      }
    }
  }
}

.form-error {
  color: $rose;
}

.menu-item-wrapper {
  outline: none !important;
}

.text-overflow-elipsis {
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}

.max-width-100 {
  max-width: 100%;
}

.border-circle {
  border-radius: 1000px;
}

.main-container {
  position: relative;
  width: 100%;
  max-width: 1425px;
  margin: 0 auto;
  padding: 0 16px;
  @media (min-width: 540px) {
    padding: 0 24px;
  }
  @media (min-width: 768px) {
    padding: 0 36px;
  }
  @media (min-width: 992px) {
    padding: 0 40px;
  }
  @media (min-width: 1265px) {
    padding: 0 80px;
  }
}

/** Leaflet styles */
.leaflet-icon {
  margin-top: -60px !important;
}

.map-section {
  display: flex;
  flex-direction: column;

  .map-view {
    flex-grow: 1 !important;
    z-index: 0;
  }
}

@media only screen and (max-width: 1200px) { 
  .map-section {
    display: none;
  }
}

@media only screen and (max-width: 768px) { 
  .map-section {
    display: none;
  }
}
