@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; 
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); 
@import url('https://netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css'); 

body {
  margin: 0;
  font-family: "Segoe UI", "Roboto",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500; 
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} 

p {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
} 

select {
  outline: none !important;
}

img, video {
  max-width: none;
  height: none;
}

@media(max-width: 768px){ 
  .gm-bundled-control-on-bottom {
    bottom: 0px !important;
    top: 50px;
  } 
} 

.border-black {
  border: 1px solid #191919
}

.border-lightgrey {
  border: 1px solid #9FA2B4 !important;
}

input[type=checkbox]:checked {
  background-color: #191919 !important;  
  width: 15px !important;
  height: 15px !important;
  /* margin: 5px; */
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance:none;
  /* outline: 1px solid #191919 !important; */
  border: 1px solid #696969 !important;
  box-shadow: none;
  font-size: 2em;
  border-radius: 2px;
}

input[type=checkbox] {  
  width: 15px !important;
  height: 15px !important;
  margin: -2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance:none;
  /* outline: 1px solid #191919 !important; */
  border: 1px solid #696969 !important;
  box-shadow: none;
  font-size: 2em;
  border-radius: 2px;
} 